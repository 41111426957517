import React from "react";
import BaseLayout from "../components/baseLayout";
import HeroTitle from "../components/heroTitle";
import { graphql, useStaticQuery } from "gatsby";

export default function Privacy() {
  return (
    <BaseLayout>
      <HeroTitle title="Privacy Policy" />
      <div className="container">
        <div className="section">
          <div className="title">1. Introduction</div>
          <div className="content">
            <p>
              TriM ("we", "us", or "our") is committed to protecting the privacy
              of our users ("you" or "your").
            </p>
            <p>
              This Privacy Policy explains how we collect, use, disclose, and
              safeguard your information when you use our IMS for geolocalized
              and meteorological data, including our web and mobile applications
              (collectively, the "Service").
            </p>{" "}
            <p>
              Please read this Privacy Policy carefully. By accessing or using
              our Service, you acknowledge that you have read, understood, and
              agree to be bound by all the terms outlined in this Privacy
              Policy.
            </p>
            <p>
              If you do not agree with our policies and practices, please do not
              use our Service.
            </p>
          </div>
        </div>

        <div className="section">
          <div className="title"> 2. Information We Collect</div>
          <div className="content">
            We collect the following types of information:
          </div>
          <div className="subtitle">2.1 Personal Information</div>
          <div className="content">
            <ul>
              <li> Name</li>
              <li>Email address</li>
              <li>GPS position (mandatory)</li>
              <li>Photos, videos, and audio recordings (optional)</li>
            </ul>
          </div>

          <div className="subtitle">2.2 User-Created Survey Data</div>
          <div className="content">
            As a IMS platform, we allow users to create their own surveys and
            collect data for them. The types of data collected through these
            user-created surveys may vary and are determined by the survey
            creator. As the platform provider, we process and store this data on
            behalf of our users.
          </div>

          <div className="subtitle">2.3 Cookies and Tracking Technologies</div>
          <div className="content">
            We do not directly use cookies. However, we use Google Analytics,
            which may use cookies and similar tracking technologies to track
            activity on our Service and hold certain information.
          </div>
        </div>

        <div className="section">
          <div className="title">3. How We Use Your Information</div>
          <div className="content">
            We use the information we collect for various purposes, including:
            <ul>
              <li>To provide and maintain our Service</li>
              <li>To notify you about changes to our Service</li>
              <li>To provide customer support</li>
              <li>
                To gather analysis or valuable information so that we can
                improve our Service
              </li>
              <li>To monitor the usage of our Service</li>
              <li>To detect, prevent and address technical issues</li>
            </ul>
          </div>

          <div className="subtitle">4. Data Retention</div>

          <div className="content">
            We will retain your personal information until you request its
            deletion. You can request the deletion of your data at any time by
            contacting us through our provided contact methods.
          </div>

          <div className="subtitle">4. Data Retention</div>
          <div className="content">
            We will retain your personal information until you request its
            deletion. You can request the deletion of your data at any time by
            contacting us through our provided contact methods.
          </div>

          <div className="subtitle">5. Data Subject Rights</div>
          <div className="content">
            Under GDPR, you have certain rights regarding your personal data.
            These include:
            <ul>
              <li>
                The right to access, update or delete the information we have on
                you
              </li>
              <li>The right of rectification</li>
              <li>The right to object</li>
              <li>The right of restriction</li>
              <li>The right to data portability</li>
              <li>The right to withdraw consent</li>
            </ul>
          </div>

          <div className="content">
            To exercise any of these rights, please contact us using the contact
            information provided in this policy.
          </div>

          <div className="subtitle">6. Data Transfers</div>
          <div className="content">
            We store and process your data within the European Union (EU). We do
            not transfer your personal data outside the EU.
          </div>

          <div className="subtitle">7. Age Restrictions</div>
          <div className="content">
            Our Service is intended for users who are 18 years of age or older.
            We do not knowingly collect personal information from anyone under
            the age of 18. If you are under 18, please do not use our Service or
            provide any personal information to us.
          </div>

          <div className="subtitle">8. Changes to This Privacy Policy</div>
          <div className="content">
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by sending an email to the address you have
            provided us and/or by placing a prominent notice on our website.
          </div>

          <div className="subtitle">9. Consent</div>
          <div className="content">
            By using our Service, you consent to our collection and use of your
            personal data as described in this Privacy Policy. For the
            collection of optional data (photos, videos, audio), we will
            explicitly ask for your permission within our application before
            collecting such data.
          </div>

          <div className="subtitle">10. Security Measures</div>
          <div className="content">
            We take the security of your data seriously. All data collected
            through our Service is securely stored, regularly backed up, and can
            be accessed only by the data owner. We implement appropriate
            technical and organizational measures to maintain the safety of your
            personal data.
          </div>

          <div className="subtitle">11. Third-Party Services</div>
          <div className="content">
            We use Google Analytics in some of our web applications to help us
            understand how our Service is used. Google Analytics may use cookies
            and other tracking technologies to collect and analyze information
            about the use of the Service. You can learn more about how Google
            uses data when you use our Service by visiting:
            https://policies.google.com/technologies/partner-sites
          </div>
        </div>

        <div className="section">
          <div className="title">4. Contact Us</div>
          <div className="content">
            If you have any questions about this Privacy Policy, please contact
            us:
          </div>
          <div className="content">
            <ul>
              <li>By email: contact@trimweb.it</li>
              <li>
                By visiting this page on our website: https://trimweb.it/privacy
              </li>
            </ul>
          </div>

          <div className="subtitle"></div>
          <div className="subtitle"></div>
        </div>

        <div className="section">
          <div className="title"></div>
          <div className="content"></div>
        </div>
      </div>
    </BaseLayout>
  );
}
